import CalendarSelector from 'react-calendar';
import React from 'react'
import 'react-calendar/dist/Calendar.css';

export default function SideBar(props) {

  const changeDate = (date) => {
    props.onChangeDate(date);
  }

  return (
    <div className="sidebar">
        <CalendarSelector
          className="mb30 no-border"
          onChange={changeDate}
          value={props.today}
          calendarType="US"
          locale="en-US"
        />

      <div className="ml20">계정별 예약현황</div>
      <div className="mt10 mb10" >
        <div className="ml20 mr10 mt5 float-left rectangle color-01"></div>
        <span className='f_15'>bnsedu01@outlook.com</span>
      </div>
      <div className="mt10 mb30">
        <div className="ml20 mr10 mt5 float-left rectangle color-02"></div>
        <span className='f_15'>bnsedu02@outlook.com</span>
      </div>

      <div className="ml20 side-title">BNS Family Site</div>
      <div className="mt10 mb10">
        <div className="ml20 mr10 mt5 float-left circle color-meeting"></div>
        <a href='https://meeting.bns.co.kr' target="_blank" rel="noreferrer noopener" className='pointer'>회의실 예약</a>
      </div>
      <div className="mt10 mb10">
        <div className="ml20 mr10 mt5 float-left circle color-car"></div>
        <a href='https://car.bns.co.kr' target="_blank" rel="noreferrer noopener" className='pointer'>차량 예약</a>
      </div>
      <div className="mt10 mb10">
        <div className="ml20 mr10 mt5 float-left circle color-agora"></div>
        <a href='https://agora.bns.co.kr' target="_blank" rel="noreferrer noopener" className='pointer'>익명 게시판</a>
      </div>
    </div>
  );
}
  