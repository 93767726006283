export default function Header(props) {
  
  const propsOpenCreationPopup = () => {
    props.openCreationPopup();
  }

  return (
    <div className="header">
        <span className="header-name">BNS 강의 예약 캘린더</span>
        <button className="writeBtn" onClick={propsOpenCreationPopup}>강의 예약</button>
    </div>
  );
}
